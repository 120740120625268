import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { AiFillCheckCircle } from "react-icons/ai"

const ProductEmbeddedWizard = () => (
  <Layout>
    <Seo title="Embedded Wizard" />
    <main>
      {/* TITLE */}
      <section>
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center my-5">
                <h1 className="fw-bolder mb-3">Embedded Wizard</h1>
                <p className="lead fw-normal text-muted mb-4">
                  革新的な GUIソリューション開発ツール
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* MAIN */}
      <section className="bg-dark">
        <div className="container">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <div className="py-lg-5 py-4">
                <img
                  className="img-fluid rounded-3"
                  src="/images/product_embedded_wizard/stm32f750-discovery-1.png"
                  alt="stm32f750-discovery-1"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row py-lg-5 py-4">
                <h1
                  className="py-lg-5 text-center text-lg-start"
                  style={{
                    color: "#fff",
                    textShadow: "0 0 20px rgba(255,255,255,0.5)",
                  }}
                >
                  組み込みシステムでも
                  <br />
                  美しく表現できる。
                  <br />
                  効率良く開発できる。
                  <br />
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Feature1 */}
      <section className="py-lg-5" id="features">
        <div className="container px-5 my-5">
          <div className="row gx-5">
            <div className="col-lg-4 mb-5 mb-lg-0">
              <h2 className="fw-bolder text-gradient">
                Embedded Wizard Studio
              </h2>
              <h4 className="mt-lg-5">
                GUI開発に特化された
                <br />
                統合開発環境
              </h4>
            </div>
            <div className="col-lg-8">
              <div className="row gx-5 row-cols-1 row-cols-md-2">
                <div className="col mb-5 h-100">
                  <h2 className="h5 fw-bold">
                    <AiFillCheckCircle />
                    &nbsp;WYSIWYGエディター
                  </h2>
                  <p className="mb-0">
                    開発PCのディスプレイ画面で見たものが、そのままANSI
                    Cソースコード生成。様々なCコンパイラに対応。
                  </p>
                </div>
                <div className="col mb-5 h-100">
                  <h2 className="h5 fw-bold">
                    <AiFillCheckCircle />
                    &nbsp;コンポーネントベースの開発
                  </h2>
                  <p className="mb-0">
                    すべての要素をコンポーネントとして扱える。保守性・拡張性に優れたUI開発を実現。
                  </p>
                </div>
                <div className="col mb-5 mb-md-0 h-100">
                  <h2 className="h5 fw-bold">
                    <AiFillCheckCircle />
                    &nbsp;強力な実行環境
                  </h2>
                  <p className="mb-0">
                    優れた実行環境のおかげで、開発PC上で実行・デバッグまで一つのツールで完結。
                  </p>
                </div>
                <div className="col h-100">
                  <h2 className="h5 fw-bold">
                    <AiFillCheckCircle />
                    &nbsp;豊富なサンプル提供
                  </h2>
                  <p className="mb-0">
                    完成度の高いサンプルが30個以上！サンプルを動かしながら学べる。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Feature2 */}
      <section className="bg-light">
        <div className="container">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <div className="row py-lg-5 py-4">
                <img
                  className="img-fluid rounded-3"
                  src="/images/product_embedded_wizard/embedded-wizard-studio.png"
                  alt="stm32f750-discovery-1"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row py-lg-5 py-4">
                <h1 className="py-lg-5 text-center text-lg-start">
                  PCにて完成。
                  <br />
                  ビルドして
                  <br />
                  書き込むだけ。
                  <br />
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Feature3 */}
      <section className="py-lg-5" id="features">
        <div className="container px-5 my-5">
          <div className="row gx-5">
            <div className="col-lg-4 mb-5 mb-lg-0">
              <h2 className="fw-bolder text-gradient">Platform Package</h2>
              <h4 className="mt-lg-5">
                組み込みOS
                <br />
                組み込みLinux
                <br />
                Webブラウザまで対応。
              </h4>
            </div>
            <div className="col-lg-8">
              <div className="row gx-5 row-cols-1 row-cols-md-2">
                <div className="col mb-5 h-100">
                  <h2 className="h5 fw-bold">
                    <AiFillCheckCircle />
                    &nbsp;多数のMCU対応
                  </h2>
                  <p className="mb-0">
                    STM32シリーズ、i.MXシリーズ、LPCXpressoシリーズ
                    <br />
                    RZシリーズ、ESP32シリーズ、PIC32シリーズ対応。
                  </p>
                </div>
                <div className="col mb-5 h-100">
                  <h2 className="h5 fw-bold">
                    <AiFillCheckCircle />
                    &nbsp;様々なOS対応
                  </h2>
                  <p className="mb-0">
                    ITRON、FreeRTOS、RTX5、ThreadX、VxWorks対応。
                    <br />
                    組み込みOS、ベアメタルもオーケー。
                  </p>
                </div>
                <div className="col mb-5 mb-md-0 h-100">
                  <h2 className="h5 fw-bold">
                    <AiFillCheckCircle />
                    &nbsp;組み込みLinux対応
                  </h2>
                  <p className="mb-0">OpenGL、フレームバッファをサポート</p>
                </div>
                {/* <div className="col h-100">
                  <h2 className="h5 fw-bold"><AiFillCheckCircle />&nbsp;</h2>
                  <p className="mb-0">
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Feature4 */}
      <section className="bg-light">
        <div className="container">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <div className="row py-lg-5 py-4">
                <img
                  className="img-fluid rounded-3"
                  src="/images/product_embedded_wizard/android.png"
                  alt="stm32f750-discovery-1"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row py-lg-5 py-4">
                <h1 className="py-lg-5 text-center text-lg-start">
                  デバイス
                  <br />
                  プラットフォーム
                  <br />
                  OSを問わない。
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* FAQ */}
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="text-center mb-5">
            <h1 className="fw-bolder">よくある質問</h1>
          </div>
          <div className="row gx-5">
            <div className="col-xl-8">
              {/* <h2 className="fw-bolder mb-3">ライセンス関連</h2> */}
              <div className="accordion mb-5" id="accordionExample">
                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Q. ライセンスモデルについて教えてください。
                    </button>
                  </h3>
                  <div
                    className="accordion-collapse collapse show"
                    id="collapseOne"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      2つのライセンスモデルを用意しております。
                      <ul>
                        <li>Professional</li>
                        <li>Small Business</li>
                      </ul>
                      <strong>Professional</strong>
                      は、本格的なアプリ開発に適します。フルソースコードで提供されますので、様々なCコンパイラに対応します。
                      <br />
                      8時間ダイレクトサポートが付いておりますので、安心して開発できます。
                      <br />
                      尚、テレワーク環境に適した
                      <span className="text-danger">
                        フローティングライセンス
                      </span>
                      もご用意しておりますので、
                      ご興味がある方はお気軽にお問い合わせください。
                      <br />
                      <br />
                      <strong>Small Business</strong>
                      は、スタートアップのような小規模ビジネスに適します。ライブラリ形式で提供されますので、対応コンパイラが限られます(GCC、EWARM、MDK-ARM)。
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Q. ライセンス費用について教えてください。
                    </button>
                  </h3>
                  <div
                    className="accordion-collapse collapse"
                    id="collapseTwo"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Embedded Wizardは、Studioと Platform
                      Packageで構成されています。
                      <br />
                      <br />
                      <strong>Professional</strong>は、
                      <span className="text-danger">
                        Studioが 4900€、Platform Packageが 3400€です。
                      </span>
                      <br />
                      Platform
                      Packageレベル1(3400€)の年間生産台数は、3000台までです。
                      <br />
                      それ以上をご検討される場合は、お問い合わせください。
                      <br />
                      Studioを追加購入することで複数の開発者が同時に開発することも可能です。
                      <br />
                      <br />
                      <strong>Small Business</strong>は、
                      <span className="text-danger">
                        Studioと Platform Packageを合わせて 2990€です。
                      </span>
                      <br />
                      年間生産台数は 300台までです。
                      <br />
                      それ以上の場合はProfessionalへの乗り換えが必要です。
                      <br />
                      複数の開発者が同時に開発する場合は、追加ライセンスのご購入が必要です。
                      <br />
                      (Studioのみ追加購入ができません)
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Q. 評価版はありますか。
                    </button>
                  </h3>
                  <div
                    className="accordion-collapse collapse"
                    id="collapseThree"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      はい。あります。評価版は下記リンクから入手できます。
                      <br />
                      <a
                        href="https://www.embedded-wizard.de/download"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Embedded Wizard Download
                      </a>
                      <br />
                      <br />
                      様々な評価ボード用評価版を用意しておりますので、ぜひお試しください。
                      <br />
                      <a
                        href="https://doc.embedded-wizard.de/platform-integration-and-build-environments"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://doc.embedded-wizard.de/platform-integration-and-build-environments
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="card border-0 bg-light mt-xl-5">
                <div className="card-body p-4 py-lg-5">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="text-left">
                      <div className="h4">
                        製品に関するお問い合わせは
                        <br />
                        メールにてご相談ください。
                      </div>
                      <p className="text-muted mb-4">
                        <a href="mailto:dioiz@dioiz.com?subject=Embedded Wizardに関するお問い合わせ&amp;cc=kmchoi@dioiz.com">
                          dioiz@dioiz.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export default ProductEmbeddedWizard
